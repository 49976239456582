import {
  Container,
  Form,
  Icons,
  Option,
  Select,
  Tooltip,
} from "@ster/ster-toolkit";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useEffect, useMemo } from "react";

import { endOfMonth, startOfMonth } from "date-fns";
import { SterWebsiteModelPortalPackageExtended } from "../../api";
import { Medium, PackageData, SubOrder } from "../../types";
import PackageSelect from "../PackageSelect";
import PeriodInput from "../PeriodInput";
import BudgetGrpsInput from "./BudgetGrpsInput";
import styles from "./SubOrderForm.module.less";
import TargetGroupSelect from "./TargetGroupSelect";

const SubOrderForm = ({
  subOrder,
  medium,
  period,
  onDeleteClick,
  onUpdateSubOrder,
  packages,
  spotLengths,
}: {
  subOrder: SubOrder;
  medium: Medium;
  period: [Date, Date];
  onDeleteClick: (id: number) => void;
  onUpdateSubOrder: (newSubOrder: SubOrder) => void;
  packages: SterWebsiteModelPortalPackageExtended[];
  spotLengths: number[];
}): JSX.Element | null => {
  const [form] = useForm<SubOrder>();

  const selectedPackage = useMemo(
    () =>
      packages.find(({ targetGroups }) =>
        targetGroups.some(
          ({ packageCode }) => packageCode === subOrder.packageCode,
        ),
      ),
    [packages, subOrder.packageCode],
  );

  useEffect(() => {
    if (!selectedPackage) {
      return;
    }

    const selectedTargetGroup = selectedPackage?.targetGroups.find(
      ({ index, packageCode }) =>
        index === subOrder.targetGroupIndex &&
        packageCode === subOrder.packageCode,
    );

    form.setFieldsValue({
      ...subOrder,
      packageName: selectedPackage.name,
      packageCode: selectedTargetGroup?.packageCode,
      channels: selectedTargetGroup?.channels,
      targetGroupIndex: selectedTargetGroup?.index,
    });
    form.validateFields(["period"]);
  }, [form, selectedPackage, subOrder]);

  const handleFormChange = useCallback(
    (changedValues: Store) => {
      onUpdateSubOrder({ ...subOrder, ...changedValues });
    },
    [onUpdateSubOrder, subOrder],
  );

  const handleTargetGroupChange = useCallback(
    (value: SelectValue) => {
      const selectedTargetGroup = selectedPackage?.targetGroups.find(
        ({ index }) => index === value,
      );

      onUpdateSubOrder({
        ...subOrder,
        targetGroupId: selectedTargetGroup?.targetGroupId,
        targetGroupIndex: value as number,
        packageCode: selectedTargetGroup?.packageCode,
        channels: selectedTargetGroup?.channels,
      });
    },
    [onUpdateSubOrder, selectedPackage?.targetGroups, subOrder],
  );

  const handlePackageChange = useCallback(
    ({
      packageName,
      packageCode,
      targetGroupIndex,
      targetGroupId,
      channels,
    }: PackageData) => {
      onUpdateSubOrder({
        ...subOrder,
        packageName,
        packageCode,
        targetGroupIndex,
        targetGroupId,
        channels,
      });
    },
    [onUpdateSubOrder, subOrder],
  );

  if (!selectedPackage) {
    return null;
  }

  return (
    <Container key={subOrder.id} className={styles.container}>
      <Tooltip title="Verwijder dit pakket">
        <button
          className={styles.delete}
          type="button"
          onClick={(): void => onDeleteClick(subOrder.id)}
          aria-label="Verwijder"
        >
          <Icons.DeleteIcon />
        </button>
      </Tooltip>
      <Form
        form={form}
        onValuesChange={handleFormChange}
        layout="horizontal"
        className={styles.suborders}
      >
        <Form.Item label="Periode" shouldUpdate>
          {({ getFieldValue }) => {
            const subOrderPeriod = getFieldValue("period");
            return (
              <PeriodInput
                medium={medium}
                period={subOrderPeriod ?? period}
                inBetween={[startOfMonth(period[0]), endOfMonth(period[1])]}
                packageCode={subOrder.packageCode}
                allowClear={false}
              />
            );
          }}
        </Form.Item>
        <Form.Item
          label="Spotlengte"
          name="spotLength"
          helpText="De tag-on is onderdeel van de spotlengte die wordt opgegeven. Bijvoorbeeld 15+5 opgeven als 20."
        >
          <Select className={styles.spotLengthSelect}>
            {spotLengths.map((sl) => (
              <Option key={sl} value={sl}>
                {sl}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          required
          label="Pakket"
          helpText={
            <div className={styles.packageHelp}>
              {medium === "tv" ? (
                <>
                  De getoonde pakketten zijn inclusief spreiding.
                  <table>
                    <tbody>
                      <tr>
                        <td>Primetime</td>
                        <td>18:00 - 00:00 uur</td>
                      </tr>
                      <tr>
                        <td>Stertime</td>
                        <td>Hele dag</td>
                      </tr>
                      <tr>
                        <td>Daytime</td>
                        <td>06:00 - 00:00 uur</td>
                      </tr>
                      <tr>
                        <td>Senioren</td>
                        <td>
                          NPO 1 06:00 - 18:00 uur
                          <br />
                          NPO 2 06:00 - 00:00 uur
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <td>7 dagen</td>
                      <td>
                        Minimaal 7 dagen aaneengesloten
                        <br />
                        06:00 - 24:00 uur
                      </td>
                    </tr>
                    <tr>
                      <td>Spits</td>
                      <td>
                        5 dagen per kalenderweek ma-vr
                        <br />
                        06:00 - 10:00 en 16:00 - 20:00 uur
                      </td>
                    </tr>
                    <tr>
                      <td>3-4 dagen</td>
                      <td>
                        Maximaal 4 dagen uitsluiten per kalenderweek
                        <br />
                        06:00 - 24:00 uur
                      </td>
                    </tr>
                    <tr>
                      <td>5-6 dagen</td>
                      <td>
                        Maximaal 2 dagen uitsluiten per kalenderweek
                        <br />
                        06:00 - 24:00 uur
                      </td>
                    </tr>
                    <tr>
                      <td>Senioren</td>
                      <td>
                        Radio 1, 4 en 5 óf NPO Radio 4 en 5<br />
                        ma-zo 06:00 - 24:00 uur
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          }
        >
          <PackageSelect
            medium={medium}
            period={period}
            onChange={handlePackageChange}
            selected={selectedPackage}
          />
        </Form.Item>
        <TargetGroupSelect
          handleTargetGroupChange={handleTargetGroupChange}
          selectedPackage={selectedPackage}
          medium={medium}
        />
        <BudgetGrpsInput medium={medium} subOrder={subOrder} />
      </Form>
    </Container>
  );
};

export default memo(SubOrderForm);
