import {
  add,
  endOfDay,
  getISODay,
  isSameMonth,
  setISODay,
  startOfDay,
  startOfMonth,
} from "date-fns";

export const getQuery = (): URLSearchParams =>
  new URLSearchParams(window.location.search);

const getFirstValidMonday = (day: Date): Date => {
  const dayINeed = 1; // monday
  const today = getISODay(day);

  if (today <= dayINeed) {
    // this week's instance
    return startOfDay(setISODay(day, dayINeed));
  }

  // next week's instance
  return startOfDay(setISODay(add(day, { weeks: 1 }), dayINeed));
};

const getEndOfPeriod = (start: Date): Date =>
  endOfDay(add(add(start, { weeks: 2 }), { days: -1 }));

export const getInitialValidPeriod = (): [Date, Date] => {
  const current = new Date();
  const start = getFirstValidMonday(current);
  const end = getEndOfPeriod(start);

  if (isSameMonth(start, end)) {
    return [start, end];
  }

  const startNextMonth = getFirstValidMonday(
    startOfMonth(add(current, { months: 1 })),
  );
  const endNextMonth = getEndOfPeriod(startNextMonth);

  return [startNextMonth, endNextMonth];
};

export const getChannelName = (abbr: string) => {
  switch (abbr) {
    case "R1":
      return "NPO Radio 1";
    case "R2":
      return "NPO Radio 2";
    case "R3":
      return "NPO 3FM";
    case "R4":
      return "NPO Klassiek";
    case "R5":
      return "NPO Radio 5";
    case "RR":
      return "Regio";
    default:
      break;
  }
  return null;
};

export const getTargetGroupName = (targetGroupId: string) => {
  switch (targetGroupId) {
    case "BTOB":
      return "Zakelijk";
    case "25+":
      return "Algemeen nut";
    case "68+":
      return "Senioren";
    default:
      return targetGroupId;
  }
};

export const packagesSortOrder = [
  "7 dagen",
  "5-6 dagen",
  "3-4 dagen",
  "Spits",
  "Senioren",
  "Zakelijk",
  "Algemeen nut",
];
